<template>
  <div>
    <vsa-list>
      <!-- Here you can use v-for to loop through items  -->
      <vsa-item v-for="(category, index) in categories" :key="index">
        <vsa-heading>
          {{category.name}}
        </vsa-heading>        
        <vsa-content>
           <ServiceCategoryInformation 
                :service-category="category.services"          
                :slug="slug"
          ></ServiceCategoryInformation>
        </vsa-content>
      </vsa-item>
    </vsa-list>
</div>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import ServiceCategoryInformation from "@/views/Stores/Components/ServiceCategoryInformation";  
import "@/assets/css/accordion-simple.css";

export default {
  name: "AccordionSimple",
  props: {
    categories: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      toggler: false,
      slide: 3,
      groupId: null
    }  
  },
  components: {
    ServiceCategoryInformation,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  }  
} 
</script>

<style scoped>

</style>