<template>
  <div v-if="isLoading">
    <Spinner  classes="mx-auto my-4"></Spinner>
  </div>
  <div v-else>
    <p v-if="isEmptyStore" class="text-dark my-5 py-5 text-center">{{ $t('notifications.error.storeNotFound') }}</p>
    <section class="bg-white" v-else>   
    <div class="col-12 bg-dark px-0"> 
        <div class="col-12 col-md-6 col-lg-4 mx-0 px-0 mx-auto">  
          <div class="row justify-content-center m-0">
              <carousel
                :perPage="1"              
                :autoplay="true"
                :loop="true"
                paginationPosition="bottom-overlay"
                paginationColor="#8585852e" paginationActiveColor="gray"
              >

                <slide
                  v-for="(picture, index) in controlGalleryPictures(store.pictures)"
                  :key="index"
                  class="mx-auto"
                >
                  <img
                    :src="picture"
                    @error="replaceByDefault"
                    class="img-gallery"
                    :alt="$t('alt.galleryImage')"
                    width="100%"
                    height="100%"

                  />
                </slide>
              </carousel>
          </div>
        </div>
      </div>
      <div>
        <div class="text-center ">
          <h2 class="text-dark font-weight-bold mb-1 mt-4">
            {{ store.name }}
          </h2>
          <h4 class="text-dark mb-2 px-4">{{ store.address }}</h4>
          <h4 class="text-dark"><i class="lni star lni-star-filled"></i><b
            class="pr-3">{{ store.reviews_mean_value }}</b>   {{store.reviews_amount}} comentarios</h4>
        </div>      
      </div>
      <div class="mx-0 col-12 mb-5 row" style="display: flex; justify-content: center;">
        <div class="col-12 text-center mb-2">
          <base-button round class="text-dark bg-white icon-btn mr-2">
              <img class="phone-icon" src="../../assets/img/icons/phone.svg">
              <a class="text-dark bold phone-text" :href="'tel:' + store.phone"><b> {{ store.phone }}</b></a>
          </base-button>
          <base-button round class="text-dark bg-white icon-btn ml-2" @click="goToMaps">
              <em class="tim-icons icon-square-pin"> </em><b>  Mapa</b>
          </base-button>
        </div>
        <div  v-if="store.phone" class="col-12 text-center">
          <base-button round class="text-dark bg-white icon-btn mr-2" @click="shareWebsite">
              <em class="tim-icons icon-send"> </em> <b>  Compartir</b>
          </base-button>
        </div>
      </div>   
      <div class="col-12 px-0 pb-4 mb-4 container-service">     
        <AccordionSimple                   
                  :categories="store.categoryServices"
                  :slug="slug" ></AccordionSimple>
      </div>
      <div>

  <FsLightbox
    :toggler="toggler"
    :slide="slide"
    :sources="imgsGallery"
    :types="[
      ...Array(imgsGallery.length).fill('image')   
    ]"
  />
</div>
      <div
        v-if="
          selectedTab === 'home' &&
          store.psromotions &&
          store.promotions.length > 0
        "
        class="my-4"
      >
        <h1 class="text-center text-dark font-weight-bold">
          {{ $t("promotions").toUpperCase() }}
        </h1>
          
      </div>
    </section>
  </div>
</template>

<script>
import {  Card, BackButton, BaseButton, TabsCarousel, Spinner } from "@/components/index";
import AccordionSimple from "@/components/AccordionSimple.vue";
import FsLightbox from "fslightbox-vue/v2";
import { Carousel, Slide } from "vue-carousel";
import ServiceCategoryInformation from "./Components/ServiceCategoryInformation";
import { mapActions, mapState } from "vuex";
import defaultImage from "../../assets/img/img-default.png";

export default {
  name: "StoreView",
  data: () => {
    return {
      toggler: false,
      slide: 1,
      imgsGallery:[],
      slug: null,
      route: "/stores",
      store: {
        name: null,
        address: null,
        latitude: null,
        longitude: null,
        categoryServices: [{active:false}],
        slug: null,
        pictures: [],        
      },
      logoPicture: null,
      mainPicture: null,
      galleryPictures: [],
      screenWidth: screen.width,
      isLoading: true,
      isEmptyStore: false,
      selectedTab: 'home',
    };
  },
  computed: {
    ...mapState(["defaultTitle"]),
  },
  methods: {
    async getItem() {
      try {
        const route = `${this.route}/${this.slug}`;
        let response = await this.axios.get(route);

        if (!response) {
          this.isEmptyStore = true;
          this.$toast.error(this.$t("notifications.error.storeNotFound"));          
          return;
        }

        if (response?.data?.status === "success") {
          this.store = response.data.data?.stores;

          this.store.pictures.forEach((picture) => {
            if (picture.type === "1") this.logoPicture = picture.url;
            else if (picture.type === "2") this.mainPicture = picture.url;
            else this.galleryPictures.push(picture.url);
          });
          this.$store.dispatch('setStoreName', this.store.name);
        } else {
          this.$store.dispatch('setStoreName', null);
        }

        this.isEmptyStore = response?.data?.status !== "success" || !response;


      } catch (error) {
        console.log(error);
        this.isEmptyStore = true;
      } finally {
        this.isLoading = false;
      }
    },
    goToMaps() {
      if (this.store.latitude !== null && this.store.longitude !== null)
        window.open(
          `https://maps.google.com/?q=${this.store.latitude},${this.store.longitude}`
        );
    },
    controlServicesPictures(pictures) {
      let mainPicture = null;
      if (pictures.length > 0) {
        pictures.forEach((picture) => {
          if (picture.type === "1") {
            mainPicture = picture.url;
          }
        });
      }
      return mainPicture;
    },
    controlGalleryPictures(pictures) {
      let gallery = [];
      if (pictures.length > 0) {
        pictures.forEach((picture) => {
          if (picture.type === "3" || picture.type === "2") {
            gallery.push(picture.url);
          }
        });
      }
      return gallery;
    },

    goToStoreService(itemSlug, uuid) {
      this.$router.push({
        name: "storeService",
        params: { slug: itemSlug, uuid: uuid },
      });
    },
    shareWebsite() {
      const url = `${process.env.VUE_APP_URL}${this.$route.params.slug}`;

      if(navigator.share) {
        navigator.share({
          title: `${this.store.name} - AS Saloon`,
          text: this.$t('sharingWebsiteLinkStoreText'),
          url,
        }).then( () => {
          this.$toast.success(this.$t("notifications.success.thanksForSharing"));
        }).catch( console.error )
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText( url ).then( () => {
          this.$toast.success(this.$t("copiedToClipboard"));
        })
      } else {
        this.$toast.error(this.$t("notifications.error.bySharingTryManually"));
      }
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
    setSelectedTab(tab) {
      this.selectedTab = tab.name;
    },
    ...mapActions(["setStoreName"])
  },
  async beforeMount() {    
  },
  async mounted(){
    this.$root.$on('galleryImagesService', urlsImages  => {        
        this.toggler     = !this.toggler;
        this.imgsGallery = Object.values(urlsImages);        
    });
    this.slug = this.$route.params.slug;
    if (this.slug != null) {
      await this.getItem();
    }    
  },
  metaInfo() {
    if(this.store.name) {
      return {
        title: `${this.store.name} - ${this.store.locality} - ${this.$t('bookAppointmentsOnline')} - ${this.$t('priceReviewsPhotos')}`,
        meta: [
          {name: 'og:title', property: 'og:title', content: this.defaultTitle},
          {name: 'og:type', property: 'og:type', content: 'website'},
         // {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
          {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.storeView.description', [this.store.name, this.store.locality])},
          {name: 'description', content: this.$t('vueMeta.storeView.description', [this.store.name, this.store.locality])},
          {name: 'og:image', property: 'og:image', content: this.store.pictures[0]?.url || ''},
          {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
        ]
      }
    }
  },
  components: {
    AccordionSimple,
    Card,
    BaseButton,
    FsLightbox,
    TabsCarousel,
    Carousel,
    Slide,
    Spinner,
    ServiceCategoryInformation,
    BackButton
  },
};
</script>

<style scoped lang="scss">
@import  '../../assets/scss/white-dashboard.scss';

.phone-icon{
  width:20px;
}

.phone-text{
  padding-left:10px
}

.icon-btn{
  height: 35px;
  width: 150px;
  padding: 0px;
  border: 2px solid #d9d9d9;
}

.card-height {
  height: 37vh;
}
.img-main {
  height: 100%;
  object-fit: contain;
}
.header-text {
  background-color: rgba(255, 255, 255, 0.6);
}
.header-text h1 {
  font-size: calc(25px + (50 - 25) * ((100vw - 300px) / (1600 - 300)));
}
.header-text p {
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
}
.box-text h2 {
  font-size: calc(15px + (30 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.box-text h3 {
  font-size: calc(15px + (25 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.box-text p {
  font-size: calc(10px + (15 - 10) * ((100vw - 300px) / (1600 - 300)));
}
.img-gallery {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.star {
  font-size: 30px;
}
.bold {
  font-weight: bold;
}
.lni-star-filled {
  color: #000;
}
@media (min-width: 1700px) {
  .container-service{
    max-width: 1700px;
    margin:auto;
  }
}
@media (max-width: 768px) {
  .img-gallery {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .btn-small-font {
    font-size: 0.675rem;
  }
}
</style>

