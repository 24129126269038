<template>
  <div class="d-flex flex-wrap justify-content-between m-auto">
    <p v-if="services.length === 0" class="text-dark m-auto">{{ $t('storeWithoutServices') }}</p>
    <div
      v-else
      v-for="(service, index) in services"
      :key="index"
      class="col-md-6 cursor-pointer px-0 px-md-3"
    >
      <div class="card-chart card-chart-pie mb-0 box-text px-0">
        <div :id="service.id"  class="row col-12 bg-card-services mx-0 my-3 pt-3">
          <div @click="getGalleryImage(service)" :class="service.imageLoaded ? 'col-4 col-md-3 container-image-service' : 'd-none'" style="display: flex; align-items: center;">
              <div class="image-container">
                  <img
                    :src="controlServicesPictures(service)"
                    :alt="$t('alt.serviceImage')"                                    
                    class="col-12 p-0 img-services"
                    width="20rem"
                    height="20rem"
                    loading="lazy"
                  />
                  <div class="search-icon-container">
                    <div class="search-icon"></div>
                  </div>
              </div>
            </div>
            <div>
          </div>
          <div  :class="service.imageLoaded ? 'col-8 col-md-9 pl-0' : 'col-12'"
                class="container-text">
            <div class="row mx-0 h-100">
              <div class="col-12 container-info px-0">
                <h2 class="col-12 mb-0 font-weight-bold text-dark text-capitalize px-0">
                  {{ service.name }}
                </h2>
                <p class="col-12 align-self-start text-dark px-0 mb-2">
                  [{{ service.time }}m]
                </p>
                <p class="col-12 align-self-start text-dark px-0 pb-2">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
          <div :class="service.imageLoaded ? 'col-4 col-md-3' : 'd-none'">
          </div>
          <div :class="service.imageLoaded ? 'col-8 col-md-9 pl-0' : 'col-12'">
             <div class="row justify-content-between col-12 px-0 mx-0 my-3">
                <div class="">
                  <h3
                    class="col-12 align-self-end card-title font-weight-bold px-0"
                    :class="service.on_promotion ? 'text-danger' : 'text-dark'"
                  >
                    <del v-if="service.on_promotion" class="mr-2 text-dark">
                      {{ service.price.toFixed(2) }}€
                    </del>
                    {{
                      service.on_promotion
                        ? `${(
                            (service.price * (100 - service.discount)) /
                            100
                          ).toFixed(2)}€`
                        : `${service.price.toFixed(2)}€`
                    }}
                  </h3>
                </div>
                <div class="col-4 px-0">
                  <base-button
                    class="col-12 btn m-0 btn-small-font px-0 btn-service"                    
                    @click="goToServiceBooking(slug, service.uuid, service.slug)"
                    >{{ $t("book") }}</base-button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import defaultImage from "../../../assets/img/img-default.png";

export default {
  name: "ServiceCategoryInformation",
  props: {
    serviceCategory: {
      type: Array,
      required:true
    },
    slug: {
      type: String,
      required:true

    },
  },
  data() {
    return {   
      galleryImgs : [],
      services: this.serviceCategory.map(service => ({ ...service, imageLoaded: false }))
    };
  },
  methods: {
    getGalleryImage( service ){
      if (service.pictures.length > 0) { 
        const urlsImages = service.pictures.filter(picture => picture.url).map(picture => picture.url);
        this.$root.$emit('galleryImagesService', urlsImages);   
      }
    },
    controlServicesPictures(service) {
      let mainPicture = "";
      if (service.pictures.length > 0) {
        service.pictures.forEach((picture, index) => {
        const isValidUrl  = new Promise((resolve, reject) => {
          let img = new Image();
          img.onload = () => resolve(picture.url);
          img.onerror = () => reject("Error al cargar la imagen");
          img.src = picture.url; // Cargar la imagen
        });

        isValidUrl
          .then((validUrl) => {            
            service.imageLoaded = true;
            picture.url = validUrl
          })
          .catch((error) => {
            service.imageLoaded = false;
            picture.url = ""                
          });

          if (picture.type === "1" && service.imageLoaded === true) {
            mainPicture = picture.url
          }          
        });
      }
      return mainPicture;
    },   
    goToStoreService(itemSlug, uuid, serviceSlug) {
      this.$router.push({
        name: "storeService",
        params: { slug: itemSlug, uuid: uuid, serviceSlug: serviceSlug },
      });
    },
    goToServiceBooking(slug, uuid, serviceSlug) {
      this.$router.push({
        name: "serviceBooking",
        params: { slug: slug, uuid: uuid, serviceSlug: serviceSlug },
      });
    },

  },
  components: {
    Card,
  },
};
</script>

<style scoped>

.bg-card-services{
  background-color: #f1f8f7;
}

.container-info{
  border-bottom: 1px #a2a2a2 solid;
  min-height: 140px;
}

.btn-service{
  background: rgb(54,208,190);
  background: linear-gradient(170deg, rgba(54,208,190,1) 4%, rgba(37,169,154,1) 42%, rgba(8,125,111,1) 73%);
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}

.img-services {
  height: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.time-size {
  font-size: 15px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.search-icon-container {
  background-color: #c8c8c8;
  position: absolute;
  top: 3px;
  right: 2px;
  width: 25px; /* Tamaño del icono */
  height: 25px; /* Tamaño del icono */
  border-radius: 20px;

}

.search-icon{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 17px; /* Tamaño del icono */
  height: 17px;
  background-image: url('../../../assets/img/icons/pngwing.png'); /* URL del icono de la lupa */
  background-size: cover;
}

.container-image-service{
  padding: 5px 20px 0px 20px;
}

@media (max-width: 1200px) {
  .container-image-service{
    padding: 5px 15px 0px 15px;
  }
}

@media (max-width: 992px) {
  .container-image-service{
    padding: 0px 10px 0px 10px;
  }
}

@media (max-width: 768px) {
  .container-image-service{
    padding: 10px 35px 0px 35px;
  }
}

@media (max-width: 576px) {
  .container-image-service{
    padding: 10px 20px 0px 20px;
  }

  .search-icon-container {
    right: 2px;
    width: 20px; /* Tamaño del icono */
    height: 20px; /* Tamaño del icono */
  }

  .search-icon{
    width: 14px; /* Tamaño del icono */
    height: 14px;
  }
}

@media (max-width: 375px) {
  .container-image-service{
    padding: 0px 15px 0px 15px;
  }
}



@media (min-width: 576px) {
  .img-services {    
    height: auto;
  }
}


</style>
